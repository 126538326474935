import React from 'react'
import Layout from '../components/Layout'
//import BlogLayout from '../components/BlogPage/BlogLayout'
import SEO from '../components/SEO'
import { injectIntl } from 'gatsby-plugin-intl'
const blog = ({ intl }) => {
  return (
    <Layout>
      <SEO title="blog" />
    </Layout>
  )
}

export default injectIntl(blog)
